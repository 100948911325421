<template>
  <v-app>
    <div class="ctk-top-bar">
      <v-navigation-drawer right v-model="drawer" app temporary>
        <v-list dense subheader class="ctk-wap-menu">
          <v-list-item to="/">
            <v-list-item-content>
              <v-list-item-title>首页</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader>睡眠研究</v-subheader>
          <v-list-item to="/researchs/1">
            <v-list-item-content>
              <v-list-item-title>睡眠研究资讯</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/researchs/2">
            <v-list-item-content>
              <v-list-item-title>睡眠科普知识</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader>解决方案</v-subheader>
          <v-list-item to="/scheme">
            <v-list-item-content>
              <v-list-item-title>好睡眠解决方案</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item to="/cases">
            <v-list-item-content>
              <v-list-item-title>睡眠环境改善案例</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-subheader>了解好睡眠</v-subheader>
          <v-list-item to="/about#introduce">
            <v-list-item-content>
              <v-list-item-title>企业介绍</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/about#history">
            <v-list-item-content>
              <v-list-item-title>发展历程</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/about#responsibility">
            <v-list-item-content>
              <v-list-item-title>社会责任</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader>新闻资讯</v-subheader>
          <v-list-item to="/news/1">
            <v-list-item-content>
              <v-list-item-title>企业动态</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item to="/news/2">
            <v-list-item-content>
              <v-list-item-title>媒体报道</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-subheader>会员中心</v-subheader>
          <v-list-item to="/member">
            <v-list-item-content>
              <v-list-item-title>会员中心</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader>联系我们</v-subheader>
          <v-list-item to="/contact">
            <v-list-item-content>
              <v-list-item-title>联系我们</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar app flat absolute class="app-bar ctk-app-bar" height="96" max-width="1280">
        <v-toolbar-title class="ctk-bar-title">
          <a href="/"><img :src="info.logo" class="top-logo" /></a>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div>
          <v-flex style="clear: both;">
            <v-toolbar-items>
              <v-btn id="menu_home" href="/" class="ml-0 hidden-sm-and-down text-none ctk-nav-btn">首页</v-btn>
              <v-menu offset-y open-on-hover nudge-top="6" nudge-left="8" class="ctk-menu">
                <template v-slot:activator="{ on }">
                  <v-btn id="menu_research" v-on="on" href="/researchs" class="text-none hidden-sm-and-down">睡眠研究</v-btn>
                </template>
                <v-list>
                  <div class="ctk-menu-space"></div>
                  <v-list-item href="/researchs/1">
                    <v-list-item-title>睡眠研究资讯</v-list-item-title>
                  </v-list-item>
                  <v-list-item href="/researchs/2">
                    <v-list-item-title>睡眠科普知识</v-list-item-title>
                  </v-list-item>
                  <div class="ctk-menu-space"></div>
                </v-list>
              </v-menu>
              <v-menu offset-y open-on-hover nudge-top="6" nudge-left="20" class="ctk-menu">
                <template v-slot:activator="{ on }">
                  <v-btn id="menu_scheme" v-on="on" class="text-none hidden-sm-and-down">解决方案</v-btn>
                </template>
                <v-list>
                  <div class="ctk-menu-space"></div>
                  <v-list-item href="/scheme">
                    <v-list-item-title>好睡眠解决方案</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item href="/cases">
                    <v-list-item-title>睡眠环境改善案例</v-list-item-title>
                  </v-list-item> -->
                  <div class="ctk-menu-space"></div>
                </v-list>
              </v-menu>
              <v-menu offset-y open-on-hover nudge-top="6" class="ctk-menu">
                <template v-slot:activator="{ on }">
                  <v-btn id="menu_about" v-on="on" href="/about" class="text-none hidden-sm-and-down">了解好睡眠</v-btn>
                </template>
                <v-list>
                  <div class="ctk-menu-space"></div>
                  <v-list-item href="/about#introduce">
                    <v-list-item-title>企业介绍</v-list-item-title>
                  </v-list-item>
                  <v-list-item href="/about#history">
                    <v-list-item-title>发展历程</v-list-item-title>
                  </v-list-item>
                  <v-list-item href="/about#responsibility">
                    <v-list-item-title>社会责任</v-list-item-title>
                  </v-list-item>
                  <div class="ctk-menu-space"></div>
                </v-list>
              </v-menu>
              <v-menu offset-y open-on-hover nudge-top="6" class="ctk-menu">
                <template v-slot:activator="{ on }">
                  <v-btn id="menu_news" v-on="on" href="/news" class="text-none hidden-sm-and-down">新闻资讯</v-btn>
                </template>
                <v-list>
                  <div class="ctk-menu-space"></div>
                  <v-list-item href="/news/1">
                    <v-list-item-title>企业动态</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item href="/news/2">
                    <v-list-item-title>媒体报道</v-list-item-title>
                  </v-list-item> -->
                  <div class="ctk-menu-space"></div>
                </v-list>
              </v-menu>
              <v-btn id="menu_member" href="/member" class="ml-0 hidden-sm-and-down text-none ctk-nav-btn">会员中心</v-btn>
              <v-btn id="menu_contact" href="/contact" class="ml-0 hidden-sm-and-down text-none ctk-nav-btn">联系我们</v-btn>
              <v-app-bar-nav-icon class="hidden-md-and-up" @click.stop="drawer = !drawer">
              </v-app-bar-nav-icon>
            </v-toolbar-items>
          </v-flex>
        </div>
      </v-app-bar>
    </div>
    <v-main>
      <!--      <router-view></router-view>-->
      <keep-alive>
        <router-view ref="router-view"></router-view>
      </keep-alive>
    </v-main>
    <Footer />
    <RightBar />
  </v-app>
</template>

<script>
import RightBar from './components/RightBar.vue'
export default {
  name: 'App',
  components: {
    Footer: () => import('@/components/Footer'),
    RightBar
  },
  data: () => ({
    keywords: '',
    afterGetData: false,
    drawer: null
  }),
  mounted () {
    this.$store.dispatch('loadInfo')
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        // this.info.logo = val.logo
        document.title = val.title
        document.querySelector('meta[name="keywords"]').setAttribute('content', val.seo_keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', val.seo_description)
        document.querySelector('link[rel*=\'icon\']').setAttribute('href', val.favicon)
      },
      deep: true
    },
    '$route' (to, from) {
      this.title(this.$route)
    }
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
    /**
     * 路由切换设置title
     * @param to
     */
    title (to) {
      // document.title = to.meta.title + '_' + this.$store.getters.companyInfo.name
    },
    homeGoFirstPage () {
      this.$refs['router-view'].homeGoFirstPage()
    }
  }
}
</script>
<style lang="scss">
@import 'sass/variables.scss';

.v-application {
  font-family: $body-font-family  !important;
}
</style>
<style lang="css">
@import 'assets/css/public.css';
</style>
