import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import https from './https'
import { WOW } from 'wowjs'
import 'wowjs/css/libs/animate.css'
import 'fullpage-vue/src/fullpage.css'
import VueFullpage from 'fullpage-vue'
Vue.use(VueFullpage)
Vue.prototype.https = https
Vue.prototype.$wow = new WOW({ live: false })
Vue.prototype.$wowd = new WOW({ boxClass: 'wowd', live: false })
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  },
  render: h => h(App)
}).$mount('#app')
