import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 获取原型对象上的push函数
const originalPush = Router.prototype.push
// 修改原型对象中的push方法
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue'),
      meta: { title: '首页', keywords: '', description: '' }
    },
    {
      path: '/scheme',
      name: 'scheme',
      component: () => import('./views/Scheme.vue'),
      meta: { title: '好睡眠解决方案', keywords: '', description: '' }
    },
    {
      path: '/member',
      name: 'member',
      component: () => import('./views/Member.vue'),
      meta: { title: '会员中心', keywords: '', description: '' }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./views/About.vue'),
      meta: { title: '了解好睡眠', keywords: '', description: '' }
    },
    {
      path: '/news/:type(\\d+)?/:page(\\d+)?',
      name: 'news',
      component: () => import('./views/News.vue'),
      meta: { title: '新闻', keywords: '', description: '' }
    },
    {
      path: '/new/:id(\\d+)',
      name: 'newsDetail',
      component: () => import('./views/New.vue'),
      meta: { title: '新闻详情', keywords: '', description: '' }
    },
    {
      path: '/cases/:page(\\d+)?',
      name: 'cases',
      component: () => import('./views/Cases.vue'),
      meta: { title: '案例', keywords: '', description: '' }
    },
    {
      path: '/case/:id(\\d+)',
      name: 'caseDetail',
      component: () => import('./views/CaseDetail.vue'),
      meta: { title: '案例', keywords: '', description: '' }
    },
    {
      path: '/researchs/:type(\\d+)?/:page(\\d+)?',
      name: 'researchs',
      component: () => import('./views/Research.vue'),
      meta: { title: '睡眠研究', keywords: '', description: '' }
    },
    {
      path: '/research/:id(\\d+)',
      name: 'researchDetail',
      component: () => import('./views/ResearchDetail.vue'),
      meta: { title: '睡眠研究', keywords: '', description: '' }
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('./views/Contact.vue'),
      meta: { title: '联系我们', keywords: '', description: '' }
    }
  ]
})
