<template>
  <div>
    <div id="right-bar-div">
      <div id="qr-content" v-if="info"><img :src="info.qrcode"/></div>
      <div id="tel-content" v-if="info">
        <p class="bar-tel-label">客服热线:</p>
        <p class="bar-tel-nu">{{info.tel}}</p>
      </div>
      <div id="msg-content" v-if="showMsgForm">
        <div class="msg-content-title">
          留言咨询
          <div class="msg-close-btn" @click="showMsgForm = false"></div>
        </div>
          <v-form
            v-model="rbMessageForm"
            ref="rbMessageForm"
            style="width:100%"
          >
            <v-row no-gutters>
              <v-col cols="4" xs="4" md="3">
                <v-subheader class="ctk-input-label">姓名:</v-subheader>
              </v-col>
              <v-col cols="8" xs="8" md="9">
                <v-text-field height="36" outlined name="name" placeholder="请输入您的姓名" :rules="[v => !!v || '请输入您的姓名']" v-model="rbFormData.name" ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4" xs="4" md="3">
                <v-subheader class="ctk-input-label">手机号:</v-subheader>
              </v-col>
              <v-col cols="8" xs="8" md="9">
                <v-text-field height="36" name="mobile" placeholder="请输入您的手机号" outlined label="" :rules="[ v => !!v || '请输入您的手机号', v => /^[1][0-9]{10}$/.test(v) || '请输入正确的手机号']" v-model="rbFormData.mobile" ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4" xs="4" md="3">
                <v-subheader class="ctk-input-label">验证码:</v-subheader>
              </v-col>
              <v-col cols="4" xs="4" md="5">
                <v-text-field height="36" name="code" placeholder="请输入验证码" outlined label="" :rules="[ v => !!v || '请输入验证码', v => /(^[\-0-9][0-9]*(.[0-9]+)?)$/.test(v) || '验证码错误']" v-model="rbFormData.code" > </v-text-field>
              </v-col>
              <v-col cols="4" xs="4" md="4">
                <div class="code-btn-div">
                  <a id="rb_send_code_btn" @click="sendRbCode">获取验证码</a>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4" xs="4" md="3">
                <v-subheader class="ctk-input-label">留言:</v-subheader>
              </v-col>
              <v-col cols="8" xs="8" md="9">
                <v-textarea dense name="content" placeholder="请输入您的咨询内容，以便我们可以更好地为您提供服务" outlined rows="3" v-model="rbFormData.content" ></v-textarea>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4" xs="4" md="3"></v-col>
              <v-col cols="8" xs="8" md="9">
                <v-btn depressed tile color="primary" @click="submitRbForm" width="88" style="margin-right: 20px;">提交</v-btn>
                <!-- <v-btn depressed tile color="default" @click="showMsgForm = false" width="88">关闭</v-btn> -->
              </v-col>
            </v-row>
          </v-form>
      </div>
      <div id="right-bar">
        <ul class="right-bar-ul">
          <li id="right-bar-qrcode" @mouseenter="showContent('qr')" @mouseleave="hideContent('qr')"><img src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/right-bar-qrcode.svg"/></li>
          <li id="right-bar-msg" v-on:click="showForm"><img src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/right-bar-msg.svg"/></li>
          <li id="right-bar-tel" @mouseenter="showContent('tel')" @mouseleave="hideContent('tel')"><img src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/right-bar-tel.svg"/></li>
          <li id="right-bar-top" v-on:click="backToTop"><img src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/right-bar-top.svg"/></li>
        </ul>
      </div>
    </div>
    <v-row justify="center">
      <v-dialog v-model="rbDialog" persistent max-width="290">
        <v-card>
            <v-card-title class="headline">操作提示</v-card-title>
          <v-card-text>{{rbReturnMsg}}</v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click="rbDialog = false" >确定</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
export default {
  data: () => ({
    showMsgForm: false,
    rbMessageForm: false,
    rbReturnMsg: null,
    rbDialog: false,
    rbFormData: {
      name: '',
      mobile: '',
      code: '',
      content: ''
    },
    rbBtnDisable: false,
    rbItv: '',
    rbScd: 60
  }),
  methods: {
    backToTop () {
      document.querySelector('.ctk-top-bar').scrollIntoView(true)
      if (this.$route.name === 'home') {
        this.$parent.$parent.homeGoFirstPage()
      }
    },
    showContent (e) {
      if (!this.showMsgForm) {
        document.querySelector('#' + e + '-content').style.display = 'block'
      }
    },
    hideContent (e) {
      document.querySelector('#' + e + '-content').style.display = 'none'
    },
    showForm () {
      this.showMsgForm = !this.showMsgForm
    },
    sendRbCode () {
      if (this.rbBtnDisable) {
        return false
      }
      if (/^[1][0-9]{10}$/.test(this.rbFormData.mobile)) {
        this.rbBtnDisable = true
        document.getElementById('rb_send_code_btn').innerText = '发送中...'
        var that = this
        this.https.get('join_code', { mobile: this.rbFormData.mobile }).then(response => {
          if (response.code === 0) {
            that.rbItv = setInterval(function () {
              if (that.rbScd > 1) {
                that.rbScd -= 1
                document.getElementById('rb_send_code_btn').innerText = '等待' + that.rbScd + '(s)'
              } else {
                document.getElementById('rb_send_code_btn').innerText = '发送验证码'
                that.rbBtnDisable = false
                that.rbScd = 60
                clearInterval(that.rbItv)
              }
            }, 1000)
          } else {
            that.rbBtnDisable = false
            document.getElementById('rb_send_code_btn').innerText = '发送验证码'
            that.rbScd = 60
            this.rbReturnMsg = response.msg
            this.rbDialog = true
          }
        })
      } else {
        this.rbReturnMsg = '请输入正确的手机号码'
        this.rbDialog = true
      }
    },
    submitRbForm () {
      this.$refs.rbMessageForm.validate()
      if (this.rbMessageForm === true) {
        this.https.get('online_message', this.rbFormData).then(response => {
          if (response.code === 0) {
            this.$refs.rbMessageForm.reset()
            document.getElementById('rb_send_code_btn').innerText = '发送验证码'
            this.showMsgForm = false
          }
          this.rbReturnMsg = response.msg
          this.rbDialog = true
        })
      }
    }
  },
  computed: {
    info () {
      let qrcode = ''
      let tel = ''
      if (this.$store.getters.companyInfo.qrcode) {
        qrcode = this.$store.getters.companyInfo.qrcode
      }
      if (this.$store.getters.companyInfo.tel) {
        tel = this.$store.getters.companyInfo.tel
      }
      return {
        qrcode: qrcode,
        tel: tel
      }
    }
  }
}
</script>
